/**
 * NOTE:
 *  ADMINのlifeplansテーブルのversion_codeに対応する。
 *  サービスそのもののバージョンはversion_codeで管理し、入力項目や結果で使う値の形式が変わった場合はスキーマ内の「バージョン」で管理する。
 *  マネイロProというサービスのversion_codeは3とし、破壊的な変更がない限りはこの値を変更しない。
 */
export const MONEIRO_PRO_VERSION_CODE = 3

/**
 * アプリケーションの実行環境を表す定数
 * Vercel の環境変数 VITE_APP_ENV で設定する想定
 */
export const APP_ENV_PROD = "production"
export const APP_ENV_PREVIEW = "preview"
